import React from "react";
import { navigate } from "gatsby";
import { Box, BoxContent } from "../components/box";
import { Button } from "../components/button";
import { ButtonContainer } from "../components/button-container";
import { H1, Text } from "../components/text";
import { Layout } from "../components/layout";
import { MaxWidthContainer } from "../components/max-width-container";
import { PageContainer } from "../components/page-container";
import { Spacer } from "../components/spacer";

const Index = () => (
  <Layout>
    <PageContainer>
      <MaxWidthContainer>
        <Box>
          <BoxContent>
            <H1>Welcome</H1>
            <Text>
              Welcome to the concept platform for the Goat Herd Health
              management system. This web app demonstrates some of the proposed
              functionalities for an offline phone/tablet data capture system,
              with the objective of collecting pertinent production animal data
              for animal health and disease surveillance and to provide guidance
              to remote animal health workers.
            </Text>
          </BoxContent>
          <Spacer height={2} />
          <ButtonContainer>
            <Button onClick={() => navigate("/form")}>Start</Button>
          </ButtonContainer>
        </Box>
      </MaxWidthContainer>
    </PageContainer>
  </Layout>
);

export default Index;
